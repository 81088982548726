/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  function configurer(){

    var menuTrigger;

    //Open close burger menu
    $('#bt-menu').on('click', function(){
      $('nav#primary').addClass('menu-open');
    });

    $('#bt-menu-close').on('click', function(){
      $('nav#primary').removeClass('menu-open');
    });

    //On scroll
    $(window).on('scroll', function() {
      if($('nav#primary').hasClass('menu-open')){
        $('nav#primary').removeClass('menu-open');
      }

      if($('body').hasClass('front-page')){
        menuTrigger = $(window).height();
      }else {
        menuTrigger = ($(window).height())/3;
      }

      scrollPosition = $(this).scrollTop();
      
      if($(window).width() <= 768){
        if (scrollPosition >= menuTrigger) {          
          $('header#header').addClass('visible');
        }else {
            $('header#header').removeClass('visible');        
        }
      }else {
        if (scrollPosition >= menuTrigger) {          
          $('#fixed-navigation').addClass('visible');
        }else {
            $('#fixed-navigation').removeClass('visible');        
        }
      }     

    });

    $('.photo-list.slider').slick({
      infinite: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      arrows: true,
    });  

    $('.video-slider').slick({
      infinite: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      arrows: true,
    }); 

    $('.fullwidth-image-slider').slick({
      infinite: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      arrows: true,
      dots: true,
    }); 

    if($('body').hasClass('home')){

      var firstImageUrl = $('#front-progress-slider .front-slider-nav').children(":first").attr('data-image');
      
      $('#front-progress-slider .front-slider-nav').children(":first").addClass("active");

      changeFrontSliderImage(firstImageUrl);

      var interval;
      var timer = function(){
      
          interval = setInterval(function(){      
          var currentActive = $('.front-slider-nav li.active').index();
          var nextSlideIndex = 1;

          if(currentActive == 0){
            nextSlideIndex = 1;
          }else {
            if(currentActive == 1){
              nextSlideIndex = 2;
            }else {
              nextSlideIndex = 0;
            }          
          }

          $('.front-slider-nav li.active').removeClass('active');

          var newImageUrl = $('.front-slider-nav li:eq('+nextSlideIndex+')').attr('data-image');
          $('.front-slider-nav li:eq('+nextSlideIndex+')').addClass('active');

          changeFrontSliderImage(newImageUrl);   

        },5000);
      };

      timer();


      $('.front-slider-nav li').on('mouseover', function() {
        if(!$(this).hasClass('active')){        

         var imageUrl = $(this).attr('data-image');
         changeFrontSliderImage(imageUrl);

         $('.front-slider-nav li.active').removeClass('active');

         $(this).addClass('active');

        clearInterval(interval);
        timer();

        } 

      });

      $('.front-progress-slider-mobile').on('init', function() {
        $('.front-progress-slider-mobile .slide.slick-active.slick-current').removeClass('slick-current');
        setTimeout(function(){
           $('.front-progress-slider-mobile .slide.slick-active').addClass('slick-current');
         }, 10);
       
      });  

      $('.front-progress-slider-mobile').slick({
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
        fade: true,
        autoplay: true,
        swipe: true,
        pauseOnHover: false,
        autoplaySpeed: 5000
      });   

      $('.slider-pourvoirie').slick({
        infinite: false,
        slidesToShow: 3.25,
        slidesToScroll: 1,
        responsive: [
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 2.25,
          }
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 1.25,
          }
        }
      ]
      }); 

      $('.fake-next').on('click', function() {
         $(this).parent().parent().parent().addClass('move');

         var current = $(this);

         setTimeout(function() {
             //current.parent().find('.slider-pourvoirie').slick('slickNext');
          }, 200);

         $(this).parent().parent().parent().find('.slick-prev').addClass('first-slide');
      });

      $('.slider-pourvoirie .slick-prev').on('click', function() {

        if($(this).hasClass('first-slide')){
          $(this).parent().parent().parent().parent().removeClass('move');
        }        
      });

      //On after change
      $('.slider-pourvoirie').on('afterChange', function(event, slick, currentSlide){


        if(currentSlide == 0){
          $(this).parent().parent().parent().find('.slick-prev').addClass('first-slide');
        }

        if(currentSlide == 1){
          $(this).parent().parent().parent().find('.slick-prev').removeClass('first-slide');
        }

      });
    }

    $('.read-more-bloc').each(function(){

      var descHeight = $(this).find('.text-wrapper .description').outerHeight();

      $(this).attr('data-desc-height', descHeight);

    });

    $('.read-more-bloc').on('click', function() {
      $(this).toggleClass('open');

      if($(this).hasClass('open')){
        var descHeight = $(this).attr('data-desc-height');
        $(this).find('.text-wrapper').css('transform', "translateY(-"+descHeight+"px)");
      }else {
        $(this).find('.text-wrapper').css('transform', "translateY(0)");
      }

      

    });


    if($('html').attr('lang') == 'en-US'){
      $('.species .text-wrapper p').readmore({ moreLink: '<a href="#">Read more +</a>', lessLink: '<a href="#"></a>' });
    }else {
      $('.species .text-wrapper p').readmore({ moreLink: '<a href="#">Lire la suite +</a>', lessLink: '<a href="#"></a>' });
    }
    



     ScrollReveal().reveal('.fade', { delay: 250, scale: 0.98, distance: "15px", duration: 1000 });

     $('.fade-js').each(function() {
      var current = $(this);

      setTimeout(function() {
         current.css('opacity', '1');
      }, 750);
     });


     //Page videos
     $('.close-video').on('click', function() {
        closeVideoLightbox();
     });

     $('.video .video-image').on('click', function() {
      var video_id = $(this).parent().find('.play-video').attr('data-id');
       openVideoLightbox(video_id);
     });

    $('.play-video').on('click', function() {
      var video_id = $(this).attr('data-id');

      openVideoLightbox(video_id);
      
    });

    $('#video-lightbox').on('click', function (){
      closeVideoLightbox();
    });

    $('.menu-item-has-children>a').bind("touchstart", function(){
        
    });
      
  }

  function closeVideoLightbox(){

    var cleanSrc = $('.video-iframe.visible').attr('clean-src');
    $(".video-iframe.visible iframe")[0].src = cleanSrc;
    

    $('#video-lightbox').addClass('hidden');
  
    $('#video-lightbox').addClass('visually-hidden');

    setTimeout(function() {
         $(".video-iframe.visible").removeClass('visible');
      }, 500);

    
    
  }



  function openVideoLightbox(videoid){
    

      $('#video-lightbox').removeClass('hidden');
      $('#video-lightbox').removeClass('visually-hidden');

      $('#video-lightbox .video-iframe[data-id="'+videoid+'"]').addClass('visible');

      var cleanSrc = $('#video-lightbox .video-iframe[data-id="'+videoid+'"] iframe')[0].src;
      $('#video-lightbox .video-iframe[data-id="'+videoid+'"]').attr('clean-src', cleanSrc);

      $('#video-lightbox .video-iframe[data-id="'+videoid+'"] iframe')[0].src += "&background=1";
      $('#video-lightbox .video-iframe[data-id="'+videoid+'"] iframe')[0].src += "&autoplay=1";

  }

  function changeFrontSliderImage(img_url) {
    $('#front-progress-slider .bg-wrapper').css('background-image','url("'+img_url+'")');
  }

  function resetDescHeight(){    

      $('.read-more-bloc').each(function(){

        var descHeight = $(this).find('.text-wrapper .description').outerHeight();

        $(this).attr('data-desc-height', descHeight);

      });
  }


  window.onload = configurer;

  window.onresize = resetDescHeight;

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
